<template>
  <div class="contentBox">
    <!-- <vue-office-pdf
      :src="pdfUrl"
      style="height: calc(100vh - 80px); width: 100%"
      @rendered="renderedHandler"
      @error="errorHandler"
    /> -->
    <!--  <iframe
      :src="
        `https://view.xdocin.com/view?src=${pdfUrl}` +
        `&watermark=${encodeURIComponent(pdfText)}`
      "
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe> -->

    <div class="contentBoxText" style="top: -300px;">
      <div class="contentItem"><span>{{pdfText}}</span> <span>{{pdfText}}</span></div>
    </div>

    <div class="contentBoxText" style="top: 0px;">
      <div class="contentItem"><span>{{pdfText}}</span> <span>{{pdfText}}</span></div>
    </div>
    <div class="contentBoxText" style="top: 300px;">
      <div class="contentItem"><span>{{pdfText}}</span> <span>{{pdfText}}</span></div>
    </div>
    <div class="contentBoxText" style="top: 600px;">
      <div class="contentItem"><span>{{pdfText}}</span> <span>{{pdfText}}</span></div>
    </div>

    <div class="contentBoxText" style="top: 900px;">
      <div class="contentItem"><span>{{pdfText}}</span> <span>{{pdfText}}</span></div>
    </div>
    <!-- <div class="contentBoxText">131212</div>
    <div class="contentBoxText">131212</div> -->
    <VueOfficePdf
      style="width: 100%; height: 100%;"
      :src="pdfUrl"
    />
    <div class="btmBox">
      <div class="return" @click="returnFun">返回上一页</div>
      <div class="btn" @click="downPdf">立即下载</div>
    </div>
  </div>
</template>

<script>
import VueOfficePdf from "@vue-office/pdf";
import { userDetail } from "@/api/lx";
export default {
  name: "pdfIndex",
  components: {
    VueOfficePdf,
  },
  data() {
    return {
      pdfUrl:
        "https://lazzen.obs.cn-east-3.myhuaweicloud.com/backstage/8ae2b657-fe0c-9767-39fc-2bf522a6f68d.pdf",
      pdfName: "",
      pdfText: "安徽盟数科技有限公司",
    };
  },
  created() {
    this.pdfUrl = this.$route.query.url;
    this.pdfName = this.$route.query.name;
    console.log("1231", this.pdfUrl);
    console.log("pdfName", this.pdfName);
    this.getUser();
  },
  mounted() {
  },
  methods: {
    getUser() {
      userDetail({}).then((res) => {
        this.user = res.data;
        this.pdfText = `${res.data.username}(${res.data.post})${
          res.data.companyName ? `@${res.data.companyName}` : ""
        } ${res.data.phone}`;
      });
    },
    renderedHandler() {
      console.log("渲染完成");
    },
    errorHandler() {
      console.log("渲染失败");
    },
    returnFun(){
      this.$router.go(-1)
    },
    downPdf() {
      // const link = document.createElement('a');
      // link.href = this.pdfUrl;
      // link.download = 'example.pdf';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.pdfUrl, true); // url 文件的完整地址 http:XXX
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const res = xhr.response;
          const link = document.createElement("a");
          link.style.display = "none";
          const url = window.URL.createObjectURL(res);
          link.href = url;
          link.setAttribute("download", `${this.pdfName}`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style lang="less" scoped>
.contentBox {
  width: 1400px;
  height: 100vh;
  margin: 0 auto;
  margin-top: 30px;
  padding: 40px 0;
  display: flex;
  position: relative;
  overflow-x: hidden;
}
.btmBox {
  position: fixed;
  width: 100%;
  height: 79px;
  background-color: #fff;
  left: 0;
  bottom: 0;
  z-index: 100000;
}
.btn {
  position: fixed;
  right: 314px;
  bottom: 20px;
  width: 112px;
  height: 38px;
  background: #00d8c3;
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.return{
  position: fixed;
  right: 460px;
  bottom: 20px;
  width: 112px;
  height: 38px;
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #606266;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdfe6;
  cursor: pointer;
}


.contentBoxText {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  color: rgba(0, 0, 0, 0.11); /* 调整颜色和透明度 */
  z-index: 100; /* 确保水印在内容下方 */
  pointer-events: none; /* 防止水印影响点击事件 */
  background-size: 100% 100%;
  font-size: 30px; /* 文字大小 */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg); /* 旋转水印 */
}
.contentItem{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
</style>